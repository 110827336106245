export default {
    init() {
        // JavaScript to be fired on all pages

        $(function() {
            $('.match-height').matchHeight();

            if ($(window).width() > 767) {
                $('.product__subcategorys a').matchHeight({byRow:false});
                $('.global__text').matchHeight({byRow:false});
                $('.global__title').matchHeight({byRow:false});
                $('.global__content').matchHeight({byRow:false});
            }
        });

        $(document).ready(function() {
            $('.fancybox').fancybox();

            $('.fancybox-ajax').fancybox({
                type: 'ajax',
                helpers: {
                    overlay: {
                        locked: false,
                    },
                },
            });

            $('.footer__newsletter a').fancybox({
                baseClass : 'fancybox-newsletter',
            });

            $('.product__menu').hover(function(){
                $('.overlay__product__menu').toggleClass('overlay__product__menu__show');
            });

            $('.touchevents .overlay__product__menu').on('click touchstart', function () {
                $(this).removeClass('overlay__product__menu__show');
            });
        });

        var sliderNav = $('.galerij__groot');
        var showArrowAndSwipe = true;
        if(sliderNav.children('a').length < 4) {
            showArrowAndSwipe = false;
        }

        $('.galerij__groot').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            asNavFor: '.galerij__klein',
            arrows: showArrowAndSwipe,
            swipe: showArrowAndSwipe,
        });

        $('.galerij__klein').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.galerij__groot',
            arrows: false,
            dots: false,
            centerMode: false,
            centerPadding: '0',
            focusOnSelect: true,
            swipeToSlide: true,
        });

        $('.projects__relation__slider').slick({
            dots: false,
            arrows: true,
            autoplay: true,
            infinite: true,
            speed: 600,
            slidesToShow: 3,
            slidesToScroll: 1,
            prevArrow: '<span class="relation__arrow__left"></span>',
            nextArrow: '<span class="relation__arrow__right"></span>',
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                  },
                },
                {
                  breakpoint: 560,
                  settings: {
                    slidesToShow: 1,
                  },
                },
              ],
        });

        $(document).ready(function(){
            $(window).scroll(function(){
                if ($(window).scrollTop() > 600){
                    $('.small__contact-box').addClass('small__contact-box--visible');
                }
                else{
                    $('.small__contact-box').removeClass('small__contact-box--visible');
                }
            });
        });

        $('.small__contact-icon').hover(function(){
            $(this).find('.small__contact-icon-text').stop(true, true).fadeIn(400);
            $(this).css('width','200px');
        },function(){
            $(this).find('.small__contact-icon-text').stop(true, true).hide();
            $(this).css('width','40px');
        });

        $('a[href*="#"]')
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function(event) {
            if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            &&
            location.hostname == this.hostname
            ) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                event.preventDefault();
                $('html, body').animate({
                scrollTop: target.offset().top,
                }, 1000, function() {
                var $target = $(target);
                $target.focus();
                if ($target.is(':focus')) {
                    return false;
                } else {
                    $target.attr('tabindex','-1');
                    $target.focus();
                }
                });
            }
            }
        });

        $('.verkooppunten__filter .btn').on('click', function() {
            let $this = $(this);

            $('.verkooppunten__filter .btn').removeClass('active');
            $(this).addClass('active');
            if ($(this).is(':last-child')){
                $('.wpgmp_search_input').val('Nederland');
            } else if ($(this).is('.europe')){
                $('.wpgmp_search_input').val('Europe');
            } else if ($(this).is('.belgie')){
                $('.wpgmp_search_input').val('Belgium');
            } else {
                $('.wpgmp_search_input').val($(this).html());
                $('.soort__dealer').val('');
            }
            $('.wpgmp_search_input').keyup();

            $('html, body').animate({
                scrollTop: $('.dealers__map').offset().top - 115,
            }, 1000);

            let zoom = $this.data('zoom');

            jQuery('#map2').data('wpgmp_maps').map.setZoom(zoom);

            let lat = $this.data('lat');
            let lng = $this.data('lng');

            jQuery('#map2').data('wpgmp_maps').map.setCenter({
                lat:lat,
                lng:lng,
            });
        });

        if ($(window).width() < 768) {
            $('.verkooppunten__filter__outer').on('click', function() {
                $(this).toggleClass('filter__open');
            });

            $('.verkooppunten__filter .btn').on('click', function() {
                $('.verkooppunten__filter').removeClass('filter__open');
            });
        }

        if ($('div').hasClass('wpgmp_map')) {
            $('.wpgmp_map img').live('click', function () {
                $('.infoBox .soort__dealer').replaceWith('<span class="soort__dealer"></span>');
            });
        }
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
