$(document).ready(function() {
    // Variables
    var primaryMenu = $('.nav-primary .nav');
    var menuIcon = $('.menu__icon');
    var primaryOverlayTouch = $('.nav-primary__overlay');
    var touchEvents = $('.touchevents');
    var body = $('body');

    // Add icon angle down to element with dropdown menu
    primaryMenu.find('> li.menu-item-has-children > a').append('<i class="fal fa-angle-down"></i>');
    primaryMenu.find('> li ul.sub-menu > li.menu-item-has-children > a').append('<i class="fal fa-angle-right"></i>');

    // Add class sub-menu1, sub-menu2 and submenu-3 to first, second and third dropdown menu
    primaryMenu.find('> li > ul.sub-menu').addClass('sub-menu1');
    primaryMenu.find('> li > ul.sub-menu > li > ul.sub-menu').addClass('sub-menu2');
    primaryMenu.find('> li > ul.sub-menu > li > ul.sub-menu li > ul.sub-menu').addClass('sub-menu3');

    // Function to add event listeners
    function addEventListeners(eventType) {
        touchEvents.find('.nav-primary .nav .menu-item-has-children').append('<div class="menu-item-has-children__overlay"></div>');
        touchEvents.find('.menu-item-has-children__overlay').on(eventType, function(){
            $(this).parent('.menu-item-has-children').addClass('menu-item-has-children__open');
        });

        // Add event listener for menu icon
        menuIcon.on(eventType, function(){
            body.toggleClass('menu__open');
            primaryOverlayTouch.toggleClass('nav-primary__overlay--active');
            $('.menu__word__open').toggle();
            $('.menu__word__close').toggle();
        });

        // Add event listener for product category
        touchEvents.find('.product__subcategorys').parent('.product__menu__inner > ul > li').addClass('has__children');
        touchEvents.find('.product__menu__inner > ul > li.has__children').append('<div class="productcategory__item__overlay"></div>');
        touchEvents.find('.productcategory__item__overlay').on(eventType, function(event){
            event.preventDefault();
            // Remove active class from all items
            touchEvents.find('.product__menu__inner > ul > li').removeClass('product__subcategorys--active');
            // Add active class to the clicked item
            $(this).parent('.product__menu__inner > ul > li').addClass('product__subcategorys--active');
        });

        // Add event listener for back button
        touchEvents.find('.back__button').on(eventType, function(){
            $(this).parent().parent().parent('.product__menu__inner > ul > li').removeClass('product__subcategorys--active');
        });
    }

    // Check for touch support and screen width
    if (('ontouchstart' in window || navigator.maxTouchPoints) && window.innerWidth <= 1024) {
        addEventListeners('touchstart');
    } else {
        addEventListeners('click');
    }

    // Add scroll event listener
    $(window).scroll(function() {
        if ($(this).scrollTop() >= 500) {
            $('.header').addClass('header--fixed');
            $('body').addClass('body--open');
        } else {
            $('.header').removeClass('header--fixed');
            $('body').removeClass('body--open');
        }
    });
});