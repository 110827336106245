export default {
    init() {
        // JavaScript to be fired on the home page
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
        $('.front__solutions__canvas__slider').slick({
            dots: false,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 1000,
            speed: 600,
            slidesToShow: 1,
            centerMode: true,
            slidesToScroll: 1,
            mobileFirst: true,
            infinite: true,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 3,
                        centerMode: true,
                    },
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 2,
                        centerMode: true,
                    },
                },
            ],
        });

        $('.front__solutions__text__slider').slick({
            dots: false,
            arrows: false,
            fade: true,
            autoplay: true,
            speed: 600,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.front__solutions__canvas__slider',
        });

        $('.branches__home__slider').slick({
            dots: false,
            arrows: true,
            // autoplay: true,
            infinite: true,
            speed: 600,
            slidesToShow: 1,
            centerMode: true,
            mobileFirst: true,
            slidesToScroll: 1,
            pauseOnHover: false,
            prevArrow: '.branches__home__navigation__prev',
            nextArrow: '.branches__home__navigation__next',
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 3,
                        centerMode: true,
                    },
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 2,
                        centerMode: true,
                    },
                },
            ],
        });

        if (window.matchMedia('(max-width: 667px)').matches) {
            $('.news__home__slider').slick({
                dots: false,
                arrows: false,
                autoplay: true,
                infinite: true,
                fade: true,
                speed: 600,
                slidesToShow: 1,
                slidesToScroll: 1,
                mobileFirst: true,
                responsive: [
                    {
                        breakpoint: 666,
                        settings: {
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 319,
                        settings: {
                            slidesToShow: 1,
                        },
                    },
                ],
            });
        }

        $('.front__solutions__text__slider').css('opacity', '1');
        $('.front__solutions__canvas__slider').css('opacity', '1');
    },
};
